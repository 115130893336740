import { template as template_ab560ca087c74adda0be79020b33b453 } from "@ember/template-compiler";
const WelcomeHeader = template_ab560ca087c74adda0be79020b33b453(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
