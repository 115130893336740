import { template as template_9db2d4e789e6487fb914e9b069ce8de7 } from "@ember/template-compiler";
const FKLabel = template_9db2d4e789e6487fb914e9b069ce8de7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
