import { template as template_7476a80bb8e74d9c8da303c2560557d8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7476a80bb8e74d9c8da303c2560557d8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
