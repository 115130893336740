import { template as template_7dfe9f8440b44279a70836fc43d9a784 } from "@ember/template-compiler";
const FKText = template_7dfe9f8440b44279a70836fc43d9a784(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
