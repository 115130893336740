import { template as template_1f0ff6c412774f4fbdca3d1d2f6491b9 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_1f0ff6c412774f4fbdca3d1d2f6491b9(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
