import { template as template_fff09140836e48458fb198ba315b089d } from "@ember/template-compiler";
const FKControlMenuContainer = template_fff09140836e48458fb198ba315b089d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
